



































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  PostFuwugailanZhongdian,
  GetDapinData,
  PostAll,
  PostStatsPeople,
  PostStatsHospital,
} from "@/request/differences";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class KanbanDaping extends Vue {
  private selectedId: any = "";
  private totalData: any = {};
  private nowTime = new Date();
  private isDataCountMock: boolean = false; // 是否为假数据
  private ifLiang: boolean = true; // 是否统计为量：人次
  private countData: any = {}; // 今日服务人次&总服务人次&重点人群服务人次分布
  private message: any = {
    重点人群分布: {
      data: [],
    },
  };
  private messagesList: any[] = [];
  private messagesListSwipperCount = 0;
  private institutionList: any[] = [];
  private currentType: string = "";
  private currentTitle: string = "";
  private currentTitleList: any[] = [];
  private currentTitleType: string = "全部";
  private interval: any = {};
  private selectedHospitals: any = [];
  private pieOption: any = {
    color: [
      "#3b82ac",
      "#d85f33",
      "#ea9924",
      "#dbb41a",
      "#96b421",
      "#3fb08c",
      "#37b1af",
      "#da4537",
    ],
    tooltip: {
      show: true,
      trigger: "item",
      formatter: (params: any) => {
        return (
          params.name +
          ":" +
          params.value +
          "&emsp;" +
          params.percent.toFixed(2) +
          "%"
        );
      },
    },
    series: [
      {
        type: "pie",
        // startAngle: 0,
        clockwise: false,
        data: [],
        label: {
          show: true,
        },
        radius: ["0", "50%"],
        // roseType: 'area'
      },
    ],
  };
  private get messageUl() {
    return this.$refs.messageUl as any;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get space() {
    const innerHeight = window.innerHeight;
    // console.log(innerHeight / 768 * 20 + 'px');
    return (innerHeight / 768) * 27 + "px";
  }
  private get titleLineHeight() {
    const innerHeight = window.innerHeight;
    return (innerHeight / 768) * 60 + "px";
  }
  @Watch("user", { immediate: true, deep: true })
  private userChange() {
    if (this.user["姓名"]) {
      this.currentTitle = this.user["姓名"];
      this.currentTitleList[0] = this.currentTitle;
      // this.currentTitleList[1] = "[妇幼版]" + this.currentTitle;
    } else {
      this.currentTitle = "中医健康服务数据平台";
    }
  }
  /**
   * @description 跳转数据报表
   */
  private goToShujubaobiao() {
    this.$router.back();
  }
  /**
   * @description 妇幼版切换
   */
  private currentTitleChange(ev: any) {
    if (ev.match(/妇幼/)) {
      this.currentTitleType = "妇幼";
    } else {
      this.currentTitleType = "全部";
    }
    this.refresh();
  }
  /**
   * @description 获得轮播数据列表
   */
  private getSwipperList() {
    const params: any = {
      hospital_ids: [],
    };
    GetDapinData(this, params).then((data: any) => {
      this.messagesList = [...data, ...data];
      this.messageUlSwipper();
    });
  }
  /**
   * @description 轮播消息函数
   */
  private messageUlSwipper() {
    // console.log(this.messageUl);
    // 获得第一个li
    const firstLi = this.messageUl.querySelector("li");
    // console.log(firstLi);
    if (firstLi) {
      const firstLiMarginTop = firstLi.style.marginTop || 0;
      firstLi.style.marginTop = parseInt(firstLiMarginTop, 10) - 1 + "px";
      this.messagesListSwipperCount++;
      if (this.messagesListSwipperCount >= 39) {
        const tempLi = this.messageUl.querySelector("li");
        tempLi.style.marginTop = 0;
        this.messageUl.appendChild(tempLi);
        this.messagesListSwipperCount = 0;
      }
      setTimeout(() => {
        this.messageUlSwipper();
      }, 60);
    } else {
      setTimeout(() => {
        this.messageUlSwipper();
      }, 1000);
    }
  }
  /**
   * @description 点击某个机构刷新正下方的数据
   * @param message 点击的某个机构
   */
  private clickInstitution(message: any) {
    this.institutionList.map((item) => {
      item.active = false;
    });
    message.active = true;
    const params: any = {
      hospital_ids: [message["hospital_id"]],
      人群: "全部",
      统计时间: "全部时间",
    };
    PostFuwugailanZhongdian(this, params).then((data: any) => {
      this.message["重点人群分布"] = data;
    });
  }
  /**
   * @description 创建饼图
   */
  private getPie(type?: any) {
    if (type) {
      this.currentType = type;
    } else {
      this.currentType = "全部";
    }
    const params: any = {
      hospital_ids: [],
      人群: this.currentType,
      统计时间: "全部时间",
    };
    PostStatsHospital(this, params).then((data: any) => {
      data["饼图"]["data"].forEach((item: any) => {
        item.name = item.title;
      });
      this.pieOption.series[0].data = data["饼图"]["data"];
    });
  }
  /**
   * @description 获取重点人群服务量分布状况数据
   */
  private zhongdianfenbu() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h.hospital_id);
    });
    const params: any = {
      hospital_ids: ids,
      人群: "全部",
      统计时间: "全部时间",
    };
    PostFuwugailanZhongdian(this, params).then((data: any) => {
      this.message["重点人群分布"] = data;
    });
  }
  /**
   * @description 测评量
   */
  private getAll() {
    const params: any = {
      hospital_ids: [],
    };
    PostAll(this, params).then((res: any) => {
      this.totalData = res;
    });
  }
  /**
   * @description 测评量统计概览-重点人群测评量分布状况
   */
  private getStatsPeople(id?: any) {
    const params: any = {
      hospital_ids: [],
    };
    if (id) {
      params.hospital_ids = [id];
    }
    PostStatsPeople(this, params).then((res: any) => {
      this.message["重点人群分布"] = res;
    });
  }
  /**
   * @description 测评量统计概览-各机构测评量分布状况
   */
  private getStatsHospital(id?: any) {
    const params: any = {
      hospital_ids: [],
      人群: "全部",
      统计时间: "全部时间",
    };
    if (id) {
      params.hospital_ids = [id];
    }
    PostStatsHospital(this, params).then((res: any) => {
      this.institutionList = res["柱状图"]["data"];
    });
  }
  private selectHospital(item: any) {
    this.selectedId = item.hospital_id;
    this.getStatsPeople(item.hospital_id);
  }
  private resetHospital() {
    this.selectedId = "";
    this.getStatsPeople();
  }
  /**
   * @description 10分钟后刷新页面
   */
  private waitToRefresh() {
    setTimeout(() => {
      this.refresh();
    }, 1000 * 60 * 10);
  }
  private refresh() {
    this.getAll();
    this.getStatsPeople();
    this.getSwipperList();
    this.getStatsHospital();
    this.getPie();
    this.waitToRefresh();
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.interval = setInterval(() => {
      this.nowTime = new Date();
    }, 1000);
    this.refresh();
  }
  /**
   * @description 销毁定时器
   */
  private destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
